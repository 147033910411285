import React, { useState } from "react"
import { useForm } from "react-hook-form"

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [message, setMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const handleSubmitForm = data => {
    // console.log(new URLSearchParams(data).toString())
    // e.preventDefault()
    // let myForm = document.getElementById("contactForm")
    // let formData = new FormData(myForm)
    // console.log(formData)
    setLoading(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(data).toString(),
    })
      .then(() => {
        setLoading(false)
        setMessage("Form successfully submitted")
        reset()
        if (typeof window !== "undefined") {
          if (window.gtag) {
            window.gtag("event", "generate_lead", { ...data })
          }
        }
      })
      .catch(error => {
        setLoading(false)
        // setMessage(error)
        console.log(error)
      })
  }

  return (
    <div className="w-full ">
      {/* <h4 className="text-center mb-12">Enquire Now</h4> */}
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        id="contactForm"
        onSubmit={handleSubmit(handleSubmitForm)}
        className={
          "flex flex-col gap-4 lg:gap-8 w-full p-4 xl:p-8 bg-gray-900 rounded-xl"
        }
      >
        <input
          type="hidden"
          name="form-name"
          {...register("form-name")}
          value="contact"
        />
        <div className={" inline w-full"}>
          <label htmlFor={"name"} className="text-gray-200 text-sm">
            Your Name:{" "}
          </label>
          <input
            id="name"
            {...register("name", { required: true })}
            placeholder="Enter Your Name"
            className="bg-transparent text-white  w-full px-4 py-2 border-b-2 border-gray-400 outline-none  focus:border-blue-500   placeholder:text-gray-400"
            type="text"
            name="name"
          />
          {errors.name && (
            <div className="text-xs mt-2 text-red-200">
              This field is required
            </div>
          )}
        </div>
        <div className="   w-full">
          <label htmlFor={"station"} className="text-gray-200 text-sm">
            Your Station:{" "}
          </label>
          <input
            id={"station"}
            {...register("station", { required: true })}
            placeholder="Station Name"
            className="bg-transparent text-white  w-full px-4 py-2 border-b-2 border-gray-400 outline-none  focus:border-blue-500   placeholder:text-gray-400"
            type="text"
            name="station"
          />
          {errors.station && (
            <div className="text-xs mt-2 text-red-200">
              This field is required
            </div>
          )}
        </div>
        <div className="  w-full">
          <label htmlFor={"email"} className="text-gray-200 text-sm">
            Email:{" "}
          </label>
          <input
            placeholder="Email Address"
            {...register("email", { required: true })}
            className="bg-transparent text-white  w-full px-4 py-2 border-b-2 border-gray-400 outline-none  focus:border-blue-500   placeholder:text-gray-400"
            type="email"
            id="email"
            name="email"
          />
          {errors.email && (
            <div className="text-xs mt-2 text-red-200">
              This field is required
            </div>
          )}
        </div>
        <div className="  w-full">
          <label htmlFor={"phone"} className="text-gray-200 text-sm">
            Your Phone:
          </label>
          <input
            placeholder="Phone Number"
            className="bg-transparent text-white  w-full px-4 py-2 border-b-2 border-gray-400 outline-none  focus:border-blue-500  placeholder:text-gray-400"
            type="phone"
            name="phone"
            id="phone"
            {...register("phone", { required: false })}
          />
        </div>

        <div>
          <label className="text-gray-200 text-sm" htmlFor={"message"}>
            Message:
          </label>{" "}
          <textarea
            id="message"
            {...register("message", { required: true })}
            name="message"
            placeholder="Your Message"
            className="bg-transparent text-white  w-full px-4 py-2 border-b-2 border-gray-400 outline-none  focus:border-blue-500  placeholder:text-gray-400"
          ></textarea>
          {errors.message && (
            <div className="text-xs mt-2 text-red-200">
              This field is required
            </div>
          )}
        </div>
        {loading === true && <p className="text-xl text-center">Loading....</p>}
        {message && <p className="text-xl text-center">{message}</p>}
        <div className="w-full text-center">
          <button
            type="submit"
            className="mx-auto px-8 py-2 text-xl border-2 border-blue-500 text-white bg-blue-500 rounded hover:bg-black hover:text-white"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  )
}

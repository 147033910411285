import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/contact-form"

const Contact = () => {
  return (
    <Layout location={"contact"} dark={true} title={"Contact Us"}>
      <div>
        <article
          className="blog-post w-full md:w-10/12 lg:w-7/12 mx-auto px-4 lg:px-2"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2
              className={`  mb-12 article-title text-center`}
              itemProp="headline"
            >
              Contact Us
            </h2>
            <p className="text-xl mb-12 text-center">
              Hit us up and we will get back to you as soon as possible.
            </p>
          </header>

          <div className="article-content mb-32" itemProp="articleBody">
            <ContactForm />
          </div>

          {/* <footer>
          <Bio />
        </footer> */}
        </article>
      </div>
    </Layout>
  )
}

export default Contact

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Contact Us" />
